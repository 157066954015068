import React from 'react';

const faqData = [
  {
    question: "How are the solo ratings calculated?",
    answer: "We crunch the numbers using two main ingredients from Board Game Geek (BGG): the average user rating and the percentage of people who recommend the game for solo play. By combining these, we get a good picture of how BGG users feel about a game's solo experience."
  },
  {
    question: "Why isn't the ranking of solo games in order of rating?",
    answer: "It's a bit like how BGG does things. The solo rating is based on the raw average rating - simple and straightforward. The ranking, however, uses an adjusted rating that gives a boost to games with more total votes (good or bad). This means the games at the top of the list aren't just highly rated, but they've also been played and enjoyed by a ton of people."
  },
  {
    question: "When was the data on this page updated?",
    answer: "The BGG data was updated in February 2025."
  },
  {
    question: "Attributions",
    answer: "Logo meeple icon: Meeple SVG Vector by game-icons.net, from Game Interface Icons 2 collection at https://game-icons.net, used under CC Attribution License"
  }
];

const FAQ = () => (
  <div className="container mx-auto px-4 py-8">
    <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
    {faqData.map((item, index) => (
      <div key={index}>
        <h2 className="text-xl font-bold mb-1">{item.question}</h2>
        <p className="mb-4">{item.answer}</p>
      </div>
    ))}
  </div>
);

export default FAQ;